@import "../../../../utils/styles/variables";

$header-cards-height: 180px;
$header-cards-margin: 20px;
$header-cards-offset: 50px;

.wrapper {
  display: flex;
  gap: 18px;
  height: 100%;
  padding: 0 18px;
}

.shippers-container {
  display: flex;
  flex-direction: column;
}

.title-container {
  padding: 8px;
  gap: 8px;
}

.shippers-column{
  width: 320px;

  // @TODO this should be avoided by fixing Skeleton component style props to use it as a container properly.
  & > span {
    display: flex;
    flex-direction: column;
    
    & > * {
      border-radius: 8px;
      margin: 8px 8px 0;
    }
  }
}

.shippers-container{
  display: flex;
  gap: 8px;
}
