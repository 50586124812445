.wrapper {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__messages {
      padding: 8px;

      max-height: 380px;

      overflow-y: scroll;
    }
    &__send-container {
      display: flex;
    }

    &__input {
      height: 35px;
      width: 100%;

      /* Gray 5 */
      border: 1px solid #e0e0e0;
      border-radius: 50px;
      padding-left: 8px;
    }
    &__send-button {
      width: min-content;
    }
  }
}
