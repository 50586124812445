.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__second-col,
  &__first-col {
    width: 100%;
    flex: 1;
  }

  &__first-col {
    flex: 2;
    margin-right: 10px;
  }

  &__second-col {
    flex: 1;
  }
&__actions-container {
  display: flex;
  flex-direction: row;
}
}

.center-button {
  margin: 0 auto;
}

.title {
  text-align: left;
}
