@import "../../utils/styles/variables";

.wrapper {
  &__icon {
    margin-bottom: 50px;

    max-width: 420px;
    max-height: 200px;
  }

  &__title {
    margin: 4px 0;
    font-family: $rawson;
    font-weight: 600;
    font-size: 36px;
    line-height: 90%;

    color: $dark-purple;

    &--selected {
      background: $orange;
      color: $white;
    }

    @media screen and (min-width: $mobile) {
      font-size: 56px;
    }
  }
}
