@import "../../utils/styles/variables";

:root {
  --border-color: rgba(171, 167, 204, 0.5);
}

.wrapper {
  width: 26px;
  height: 22px;

  margin-right: 10px;

  border-width: 1px;
  border-style: solid;
  border-color: var(--border-color);

  &--selected {
    border-color: $orange;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__label {
    font-family: $rawson-medium;
    font-size: 14px;
    line-height: 26px;

    color: $day-purple-50;

    &--selected {
      color: $orange;
    }
  }

  &__input {
    display: none;
  }

  &:last-child {
    margin-right: 0;
  }
}
