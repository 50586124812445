@import "../../../utils/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &__text {
    max-width: 380px;
    line-height: 20px;
    margin: 0;
    padding: 4px 12px;

    text-align: right;

    background: linear-gradient(95.01deg, #b426d1 -13.07%, #7419fb 102.69%);
    color: #fff;

    box-shadow: -12px 10px 45px rgba(0, 0, 0, 0.07);
    border-radius: 16px;

    align-self: flex-end;
    font-family: $rawson-medium;

    &--incoming {
      text-align: left;
      background: #fff;
      color: #000;
      align-self: flex-start;
    }
  }

  &__time {
    align-self: flex-end;
    margin: 0 6px;
    color: gray;

    &--incoming {
      align-self: flex-start;
    }
  }
}
