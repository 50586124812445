@import "../../utils/styles/variables";

.avatar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (max-width: $mobile-breakpoint) {
    padding: 2px 12px;
  }
}

.card-container {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shipper-loader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100px;
}
