.wrapper {
  position: absolute;
  top: 0;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  z-index: 10;

  &__modal {
    min-width: 280px;
    min-height: 140px;

    display: flex;

    justify-content: space-evenly;
    align-items: center;

    flex-direction: column;
  }
}

.message {
  color: blue;
}
