@import "../../utils/styles/variables";

.menu {
  padding: 0;
  font-family: $rawson;
  font-weight: 400;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  color: $dark-purple;

  &__title {
    text-align: left;
    margin-left: 8px;
    color: $violet;
  }
}

.wrapper {
  padding: 18px;
  padding-top: 24px;
  padding-left: 4px;
}
