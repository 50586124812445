@import "../../utils/styles/variables";

.login-wrapper {
  width: 100vw;

  --template-columns: 1fr;

  display: grid;
  grid-template-columns: var(--template-columns);

  &--register {
    &__left-column {
      &__icon {
        max-height: 300px !important;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &--register {
      --template-columns: 5fr 7fr;
    }
    --template-columns: 1fr 1fr;
  }

  &__left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 18vh 4vw;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.wrapper {
  padding: 0;

  width: 100%;
  max-width: 1280px;

  text-align: center;

  &__content {
    flex: 1;
    max-height: calc(100vh - 215px);
  }

  &__main {
    height: calc(100% - 24px);

    margin: 12px;

    display: flex;
    flex-direction: column;

    gap: 12px;

    @media screen and (max-width: $mobile-breakpoint) {
      margin: unset;
    }
  }

  &__sidebar {
    width: 400px;
  }

  &--lock-scroll {
    overflow-y: hidden;
  }
  
  @media screen and (max-width: $mobile-breakpoint) {
    margin: 0;
  }
}
