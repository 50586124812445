$overlay-z-index: 1;

.modal {
  width: 300px;

  background: #fff;  
  padding: 20px;
  
  display: flex;
  flex-direction: column;  
  
  position: fixed;
  top: 50%;
  left: 50%;
  
  border-radius: 8px;
  box-shadow: 4px 4px 20px 0px rgba(95, 40, 138, 0.16);
  z-index: $overlay-z-index + 1;
  
  transform: translate(-50%, -50%);
  transition: visibility 0s 0.5s, opacity 0.5s linear;
  
  visibility: hidden;
  opacity: 0;

  input {
    width: 80%;
    margin: auto;
    color: var(--Fletti_darkpurple, #21014c);
    font-family: Rawson;
    font-size: 16px;
    font-style: normal;
    font-weight: 650;
    line-height: normal;
    text-align: center;
    border-radius: 50px;
    border: 1px solid rgba(171, 167, 204, 0.62);
    padding: 15px;
    background: var(--Purple-background_Day, #faf9fe);
  }
}

.modal.visible {
  visibility: visible;
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: $overlay-z-index;
}

.buttons-container {
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 20px;
}
