.wrapper {
  position: absolute;

  max-width: 220px;
  max-height: 128.252px;

  border-radius: 8px;
  border: 1px solid var(--Gray-5, #e0e0e0);

  background: #fff;
  box-shadow: -12px 10px 45px 0px rgba(0, 0, 0, 0.02);
  
  display: none;
  flex-direction: column;
  gap: 4px;
  padding: 16px 20px;

  align-self: flex-end;
  margin-right: 24px;

  cursor: default;

  &--visible {
    display: flex;
  }

  .information {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    color: var(--Gray-3, #828282);
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: var(--Gray-1, #333333);
    }
  }
  .deactivate-link {
    display: flex;
    width: 174px;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid var(--gradientfletti3, #eb5757);
    background: linear-gradient(90deg, #ff00d6 0%, #ff4900 50%, #ff00b3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
