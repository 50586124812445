@import "../../../../utils/styles/colors";

.wrapper {
  &__markers-container {
    display: flex;
    justify-content: space-evenly;

    padding: 0 20px;

    & > div:first-child {
      span {
        display: none;
      }
    }
  }
}
