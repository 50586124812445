@import "../../utils/styles/colors";

.wrapper {
  display: flex;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 70px;
  padding: 0;

  margin-right: 8px;
  padding-top: 10px;

  border: 2px solid $day-purple;
  border-radius: 6px;

  &:last-child {
    margin-right: 0;
  }

  &--selected {
    border-color: $purple;
  }
}
