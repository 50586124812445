.wrapper {
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
}

.avatar {
  width: 50px;
  height: 50px;

  border-radius: 50%;

  &__edit {
    width: 15px;
    height: 15px;

    margin-top: -15px;
    margin-left: 35px;
    padding: 0;

    background-color: #fff;
    border-radius: 50%;

    transform: translateZ(1px);

    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);

    &__icon {
      margin-left: 4px;
      margin-top: 10px;
    }
  }
}
