.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  align-items: center;

  &__title {
    margin-bottom: 0;
    align-self: flex-start;
  }

  &__row {
    display: flex;

    margin-bottom: 18px;
  }
}
