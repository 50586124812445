$header-height: 40px;
$info-card-margin: 68px;

.wrapper {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  height: calc(100vh - ($header-height + $info-card-margin));
}

.title-container {
  display: flex;
  justify-content: space-between;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
}

.title {
  display: flex;
  gap: 8px;
}

.shippers-container { 
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px 12px;
}

.handle {
  width: 180px;
  margin: 0 auto 12px;
  height: 4px;
  border-radius: 2px;
}
