.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 4px 8px;
  box-sizing: border-box;
}

.lines-container {
  display: flex;
  flex-direction: column;
  width: 30vw;
  max-width: 170px;
  gap: 8px;
  padding: 8px;
}