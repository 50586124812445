.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  & > div {
    width: 100%;

    &:last-child {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__header {
    display: flex;
    margin: 12px auto;
    justify-content: space-evenly;
    width: 80%;
    margin-top: 32px;

    &__info {
      h4 {
        margin: 0;
        text-align: left;
      }

      &__username {
        font-size: 12px;
      }
    }
  }

  &__details {
    margin-top: 32px;
  }

  &__row {
    display: flex;
    width: 100%;

    & > * {
      flex: 1;
    }
  }
}

.shippers-available {
  text-align: center;
  padding: 12px;

  &__btn {
    margin: 24px auto 0;
  }
}

.row {
  display: flex;
  align-items: center;

  &__divider {
    margin: 0 12px;
  }
}
