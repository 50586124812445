.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & > div {
      flex: 1;
      margin: auto 16px;
    }
  }
  &__packages-to-drop {
    display: flex;
    flex-direction: column;

    padding-bottom: 20px;
  }
}

.card-container {
  padding: 18 32px;
  width: calc(100% - 32px);
  margin-top: 24px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    &__place-autocomplete {
      flex: 3.1 !important;
    }

    & > div {
      flex: 1;
      margin-right: 16px;

      &:first-child {
        flex: 2;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
