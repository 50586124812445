$gap: 18px;

.wrapper {
  margin-bottom: 30px;

  &__row {
    display: flex;
    align-items: center;

    &__divider {
      margin: 0 12px;
    }
  }

  &__first-row,
  &__second-row {
    display: grid;
    gap: $gap;
  }

  &__first-row {
    grid-template-columns: 4fr 5fr;

    &__steps-wrapper {
      max-height: 600px;
      overflow-y: scroll;
    }

    &__skeleton {
      display: flex;
      flex-direction: column;

      padding-top: 60px;

      &__line {
        width: calc(100% - 50px);
        align-self: flex-end;
        margin-bottom: 16px;
      }
    }
  }

  &__second-row {
    margin-top: $gap;
    grid-template-columns: 3fr 6fr;

    &__skeleton {
      &__avatar {
        margin: 36px;
      }
    }
  }

  &__status {
    margin: $gap auto;
    padding-bottom: 36px;
  }

}

.button {
  color: #fff;
}

.status-skeleton {
  margin: 18px 0;
  padding: 0;
  padding-top: 12px;

  &__container {
    justify-content: space-evenly;
  }

  &__item {
    flex-direction: column;
  }
}