@import "../../../../utils/styles/colors";

$marker-size: 48px;
$margin: 4px;

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: self-start;
  text-align: center;

  flex: 1;

  &:first-child {
    flex: unset;
  }
  
  &__line {
    display: inline-block;
    width: 100%;
    margin-top: calc(#{$marker-size} / 2 + #{$margin});
    border-bottom: 1px solid $orange;

    &--in-progress,
    &--pending {
      border-image: url(http://i.stack.imgur.com/wLdVc.png) 1 round;
    }
  }

  &__image-container {
    width: $marker-size;
    height: $marker-size;

    border-radius: 50%;

    background-color: gray;

    margin: $margin auto;

    display: flex;

    justify-content: center;
    align-items: center;

    &--in-progress {
      background-color: $purple;

      &::before {
        content: "";
        position: absolute;

        width: calc(#{$marker-size} + #{$margin});
        height: calc(#{$marker-size} + #{$margin});

        border-radius: 50%;
        border: 2px solid $purple;
      }
    }

    &--done {
      background-color: $green;
    }

    &--isStopPending {
      background-color: $orange;
    }
  }

  &__title {
    position: absolute;
    margin: 0;
    margin-left: -1%;

    &--done {
      color: $violet;
    }
    &--in-progress {
      color: $purple;
    }
    &--pending {
      color: gray;
    }
  }
}
