@import "../../utils/styles/variables";

.label {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;

  width: 100%;
  margin: 0 4px 12px;
  color: $dark-purple;
  
  font-family: $rawson-medium;
  font-size: 16px;
  line-height: 17px;
  
  &__required {
    color: red;
    margin-right: 4px;
  }
}
