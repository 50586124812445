@import "./../../utils/styles/colors";

.wrapper {
  width: 100%;

  &__logo {
    padding: 20px;
  }

  &__policy {
    margin: 20px;
    &__text {
      text-align: justify;
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr;

    margin: 0 auto;

    flex-direction: column-reverse;
    background-color: $violet;
    color: #fff;
    padding: 8px 8px 18px 24px;

    &__nav {
      grid-row: auto / span 2;
    }

    &__subscribe {
      grid-row: 1;
      grid-column: 1 / span 2;
    }

    &__copyright {
      display: flex;
      align-items: flex-end;
      grid-column: 2;
      grid-row: 2;
      padding-bottom: 28px;

      &__logo {
        filter: invert(100%) contrast(0) brightness(170%);
        max-width: 180px;
        padding-bottom: 16px;

        margin-right: 24px;
      }
    }

    &__text {
      grid-column: 1 / span 2;
      text-align: center;
    }
  }
}
