.wrapper {
  position: relative;
  width: 100%;
}
.input {
  padding-left: 32px;
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 50px;
  border: 1px solid #aba7cc9e;
  background-color: #faf9fe;
}

.icon {
  position: absolute;
  top: calc(50% - 7px);
  left: 15px;
}
