@import "../../utils/styles/variables";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 0 8px;
  
  @media screen and (max-width: $mobile-breakpoint) {
    grid-template-columns: 1fr;
  }
}

.button {
  margin: 2em auto;
}