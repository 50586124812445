@import "../../utils/styles/variables";

.wrapper {
  display: flex;
  height: 100%;
}

.shippers-container{
  overflow-y: scroll;
  overflow-x: hidden;
  transition: width 350ms ease-in-out;
  width: 430px;

  &--collapsed {
    width: 130px;
  }
}

.filtersContainer {
  background-color: white;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  padding: 18px;
  justify-content: center;
}

.collapsible {
  margin: 0px;

  &-button {
    margin: 0;
    padding: 24px 6px;
    border: 1px solid #ebe9f9;
    border-radius: 0px;
    border-left: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    transition: transform 200ms ease-in-out;

    &--closed {
      transform: rotateZ(180deg);
    }
  }

  &--divider-vertical {
    background-color: #ebe9f9;
    width: 1px;
    height: calc(100% - 50px);
  }
}