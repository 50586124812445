@import "../../utils/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  &__days-container {
    display: flex;
    flex-direction: row;
  }
}
