@import "../../utils/styles/variables";

.wrapper {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  color: $black;

  &--two-columns {
    grid-column: 1 / span 2;
  }
}

.error {
  color: red;
  font-family: $rawson;
  font-size: 10px;
  margin-bottom: -12px;
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  border-bottom: 1px solid $day-purple-50;

  &--bordered {
    border: 1px solid $soft-violet;
    border-radius: 20px;
    padding: 4px 0;
    color: #fff;

    margin-right: 24px;
  }

  &--file {
    background: #fff;

    border: 1px dashed $purple;
    box-sizing: border-box;
    border-radius: 8px;

    &::before {
      content: "Cargar Foto";
      padding: 12px 4px;
      width: 100%;
      align-self: center;
      color: $purple;
      cursor: pointer;
    }
  }

  &--checkbox {
    width: unset;
    flex-direction: row-reverse;
    flex: 1;
    justify-content: center;
    height: 50px;

    & > label {
      flex: 8;
      margin: 0;
      margin-top: 7px;
    }

    & > div {
      flex: 1;
      border: 0;
    }
  }

  &--radio {
    flex: 1;
    flex-direction: row-reverse;
    border: 0;

    & > input {
      min-width: 28px;
    }

    & > * {
      flex: 1;
    }

    & > label {
      flex: 3;
      margin-bottom: 0;
      align-items: center;
      justify-content: flex-start;
      margin-top: 4px;
    }

    & > div {
      max-width: 27px;
    }
  }

  &--error {
    border-color: red;
  }

  &__icon {
    flex: 1;
    margin-top: 5px;
    margin-right: 2px;
  }

  &__element {
    width: 100%;
    height: 24px;

    margin-left: 4px;

    background: transparent;
    border: 0;
    color: $dark-purple;
    font-family: $rawson-medium;
    font-size: 16px;

    &--bordered {
      color: #fff;
    }

    &--file {
      display: none;
    }

    &--checkbox {
      width: unset;
    }

    &::placeholder {
      color: #bdbdbd;
    }

    &:focus {
      outline: none;
    }
  }
}
