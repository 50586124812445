@import "../../utils/styles/variables";

.search-bar {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 16px;

  margin: 4px;
  padding: 12px;

  &__right-box {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.list,
.trackinglist {
  padding: 8px;
  list-style: none;
}

.trackinglist {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  position: relative;
  
  @media screen and (max-width: $mobile-breakpoint) {
    max-height: 100%;
    padding-bottom: unset;
    position: relative;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #aba7cc7d;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $purple;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

.skeleton {
  &__row {
    margin-bottom: 24px;
  }
}
