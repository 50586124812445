.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
