.wrapper {
  display: flex;
  flex-direction: column;

  &__title {
    align-self: start;
    margin-bottom: 0;
  }

  &__add-stop-button {
    align-self: end;
  }
}
