@import '../../utils/styles/variables';

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;



  @media screen and (max-width: $tablet)  {
    background-color: white;
  }
}

.header,
.column-container,
.info-row {
  margin: 0 8em;
  
  @media screen and (max-width: $desktop) {
    margin: 0 12px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 18px 42px 0px 42px;

  & > img {
    width: 80px;
  }

  @media screen and (max-width: $tablet) {
    margin: 12px 12px;
  }
}

.body {
  background-image: url('../../../src/assets/images/landing_background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1200px;

  margin: 0 auto;
}

.fletti-logo {
  width: 80px;
}

.login-button {
  color: $orange;
  padding: 0px;
}

.column-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__left {
    display: flex;
    flex-direction: column;

    color: $dark-purple;
    font-weight: 600;

    &__button-text {
      font-size: 20px;
    }

    & > div > p {
      text-align: center;
      font-size: 20px;
      margin: 0;
    }

    & > div > h2 {
      color: $dark-purple;
      font-size: 54px;
      font-family: $rawson-medium;
      text-align: center;
      margin-bottom: 10px;
      
      & > strong {
        font-size: 54px;
        font-family: $rawson-extra-bold;
      }
    }

    & > div {

      & > button {
        margin: 0 auto;
        margin-top: 44px;
        height: 54px;
      }
    }

    & img {
      margin-right: 16px;
    }

    & .info {
      margin-top: 44px;
      margin-left: 42px;

      p {
        margin: 0px;
        font-size: 20px;

        &:last-of-type {
          margin-top: 20px;
        }
      }

      @media screen and (max-width: $tablet) {
        margin: 32px 0px 0px 0px;
        text-align: left;
      }

      @media screen and (max-width: $mobile) {
        p {
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      align-items: center;

      & > div {
        text-align: center;

        & > button {
          margin: 0 auto;
          margin-top: 32px;
        }
      }
    }
  }

  &__right {
    display: flex;
    text-align: center;
    justify-content: flex-end;

    & > img {
      margin-top: 20px;
      width: calc(100% - 36px);
      max-width: fit-content;
      border-radius: 6px;
      padding: 12px;
      background-color: white;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    }

    @media screen and (max-width: $tablet) {
      justify-content: center;
      padding: 12px;
    }
  }

  @media screen and (max-width: $tablet) {
    grid-template-columns: 1fr;
  }
}

.how-it-works {
  text-align: center;
  margin: auto 0;

  & h3 {
    font-size: 26px;
    font-family: $rawson-bold;
    margin-bottom: 6px;
  }

  &__divider {
    background-color: #e2e2e2;
    height: 5px;
    width: 110px;
    margin: 0 auto;
    margin-bottom: 12px;
  }
  @media screen and (max-width: $tablet) {
    margin-top: 32px;
    background-color: $soft-gray;
  }
}


.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;


  &__card {
    padding: 18px 16px;
    height: calc(165px - 36px);
    width: calc(250px - 32px);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.25);
    text-align: center;
    position: relative;

    & > div:first-of-type {
      position: absolute;
      font-size: 88px;
      font-family: $rawson-bold;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(45deg, rgba(180, 38, 209, 0.15), rgba(116, 25, 251, 0.15)); /* Definición del gradiente */
      -webkit-background-clip: text; 
      -webkit-text-fill-color: transparent;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    & > p {
      margin: 0px;
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
      z-index: 2;
    }

    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: row;
      width: 300px;
      height: 120px;
      align-items: center;
      padding: 0;

      & > div {
        height: 100%;
        width: 87px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $soft-gray;
        border-radius: 10px 0 0 10px;
      }

      & > p {
        margin: 0 auto;
        max-width: 200px;
      }
    }
  }
  

  @media screen and (max-width: $tablet) {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;


  background-color: $dark-purple;
  color: white;

  padding: 0 42px;

  height: 100px;

  & > div:first-child {
    display: flex;
    gap: 18px;
    
    p {
      font-size: 14px;
    }
  }

  & > p {
    font-size: 20px;
    text-align: center;
    margin: 0 12px;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  & .whatsapp--wrapper {
    display: flex;
    align-items: center;

    & button {
      min-width: 151px;
    }

    @media screen and (max-width: $tablet) {
      position: absolute;
      right: 12px;
      top: -62px;
    }

    & > :last-child {
      @media screen and (max-width: $tablet) {
        display: none;
      }
    }
  }

  & .whatsapp {
    padding: 0px;
    margin: 0px;
    min-width: 50px !important;
    height: 50px;
    margin-right: -25px;
    z-index: 3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #56d062, #0c7016);
    cursor: pointer;

    @media screen and (max-width: $tablet) {
      margin-right: 0px;
    }
  }
  
  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: calc(100% - 24px);
    height: 54px;

    padding: 0px 12px;
  }
}
