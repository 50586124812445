$default-height: var(--default-size, 16px);
$default-size: $default-height;
$base-color: #ddd;
$shine-color: #e8e8e8;

@mixin mask-image($value) {
  background-image: $value; /* None yet / Non-standard */
}

@mixin mask-size($value) {
  -webkit-mask-size: $value; /* Chrome, iOS, Safari */
  mask-size: $value; /* None yet / Non-standard */
}

@mixin mask-position($value) {
  -webkit-mask-position: $value; /* Chrome, iOS, Safari */
  mask-position: $value; /* None yet / Non-standard */
}

@mixin background-gradient {
  background-image: linear-gradient(-75deg, $base-color 25%, $shine-color 30%, $base-color 30%);
  background-size: 100vw;
}

.skeleton-container {
  align-items: center;
  display: flex;
  justify-content: center;

  &__circle,
  &__square,
  &__line {
    background-color: #f0eef0;
    display: inline-block;
    border-radius: 8px;

    background: linear-gradient(90deg, $base-color 25%, $shine-color 37%, $base-color 63%);
    background-repeat: no-repeat;
    background-size: 400% 100%;
    animation: shine 1400ms ease infinite;
  }

  &__line {
    width: 100%;
    height: $default-height;
  }

  &__circle {
    width: $default-size;
    height: $default-size;
    border-radius: 50%;
  }

  &__square {
    width: $default-size;
    height: $default-size;
  }

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--single-line {
    justify-content: flex-start;
    width: 100%;
  }
}

@keyframes shine {
  0% {
    background-position:100% 50%
  }
  100% {
    background-position:0 50%
  }
}