@import "../../utils/styles/variables";

.wrapper{
  overflow-y: hidden;
  touch-action: none;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 8px;

  & > small {
    background-color: $violet;
    color: #e0e0e0;
    border-radius: 14px;
    padding: 4px;
    min-width: 18px;
  }
}

.content {
  width: 100%;
  height: 100%;
  position: fixed; 

  margin-top: -20px;
  
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
  z-index: 3; 

  box-shadow: -2px 3px 14px -3px rgba(0,0,0,0.4);
  -webkit-box-shadow: -2px 3px 14px -3px rgba(0,0,0,0.4);
  -moz-box-shadow: -2px 3px 14px -3px rgba(0,0,0,0.4);

  background-color: #fff;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;  

  &__container {
    display: flex; 
    flex-direction: column;

    justify-content: start; 
    align-items: center; 
    padding-bottom: 0;
    min-width: 320px;
    
    &--closed {
      min-width: unset;
    }
    
    @media screen and (max-width: $mobile-breakpoint) {
      min-width: unset;
      padding-top: 18px;
      justify-content: space-between;
    }
  }
}

.handle {
  margin-bottom: 8px;
  width: 120px;
  height: 4px;
  background: #e0e0e0;
  border-radius: 2px;

  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }
}

.children-container {
  width: 100%;
  overflow-y: scroll;
  padding: 0;
}