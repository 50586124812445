$animation: cubic-bezier(0.51, -0.16, 0.6, 1.26);
$duration: 400ms;

.wrapper {
  max-height: 110px;

  transition: max-height $animation $duration;

  &--open {
    max-height: 450px;
  }
}

.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extra-info {
  display: flex;
  justify-content: space-between;

  margin-top: 20px;

  position: relative;
  z-index: -1;

  max-height: 80px;
  overflow: hidden;

  transform: translateY(-100px);

  transition: all $animation $duration;

  &--open {
    max-height: 490px;
    transform: translateY(0px);
    margin-bottom: 20px;
    z-index: 1;
  }

  &__box {
    flex: 1;
    padding: 20px;

    margin-right: 10px;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.subtitle,
.title {
  margin: 10px;
}

.title {
  display: flex;
  align-self: flex-start;
  font-weight: 600;
}

.toggle-button {
  transition: transform ease-in-out 200ms;

  &--open {
    transform: rotate(180deg);
  }
}

.card-footer-container {
  display: flex;
  align-items: center;
}