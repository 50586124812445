@import "../../../utils/styles/colors";

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;

  &--selected {
    background-color: #fff;
    border-left-color: $orange;
    border-left-width: 3px;
    border-left-style: solid;
  }

  &__icon {
    margin: 10px;
  }

  &__text {
    color: $dark-purple;
    font-weight: bold;
    font-size: 16px;
    padding: 18px 0;
  }
}
