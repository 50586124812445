.wrapper {
  position: absolute;
  left: 0;
  height: 100%;

  width: 80%;
  max-width: 280px;

  background-color: rgba(16 18 27 / 40%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  -webkit-box-shadow: 9px 7px 25px 1px rgba(0,0,0,0.75);
  -moz-box-shadow: 9px 7px 25px 1px rgba(0,0,0,0.75);
  box-shadow: 9px 7px 25px 1px rgba(0,0,0,0.75);

  z-index: 3;
}

.list {
  padding-inline: 20px;
}

.menu-link {
  list-style-type: none;
}

.menu-link > a,
.row > a {
  text-decoration: none;
  color: #f9fafb;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  gap: 8px;
}

.row {
  display: flex;
  align-items: center;
  gap: 4px;
  color: white;
  text-decoration: none;
}

.menu-button {
  color: #fff;
}