@import "../../../../utils/styles/colors";

.modal {
  border: 1px solid $purple;

  position: fixed;
  margin-top: 48px;
  margin-left: 100px;

  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  align-items: center;
  width: 135px;

  z-index: 3;
  
  &--mobile {
    margin-top: 148px;
    margin-left: calc(80% - 125px);
  }

  &__input {
    width: 100%;
    gap: 8px;
  }
}

.button {
  padding: 0px;
  margin: 0px;
  height: 35px;
  min-width: 35px;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #64458f;
}
