@import "./../../utils/styles/colors";
@import "./commons";

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  background-color: #f2f2f2;

  &__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    padding: 8px;


    @media screen and (min-width: $container) {
      justify-content: space-around;
    }
  }
  &__hero,
  &__benefits-for-you {
    padding: 56px 28px;

    &__title {
      font-weight: 600;
      font-size: 40px;
      line-height: 101.47%;

      text-align: center;

      color: #21014c;

      @media screen and (min-width: $break-point) {
        font-size: 70px;
      }
    }

    &__description {
      max-width: 680px;
      margin: 0 auto 18px;

      font-size: 18px;
      line-height: 150%;

      text-align: center;
      letter-spacing: 0.01em;

      /* Fletti_gray */
      color: #797382;
    }
  }

  &__hero {
    &__left-column {
      &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: $x-small-break-point) {
          flex-direction: row;
        }

        @media screen and (min-width: $break-point) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__hero {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #f2f2f2;

    &__left-column {
      display: flex;
      flex-direction: column;

      &__simple {
        align-self: center;
      }

      &__text {
        font-weight: bold;
        text-align: justify;
        margin: 18px 20%;
      }
    }

    &__image {
      margin: 52px 12px 0;
      width: 100%;
      max-width: 500px;
    }

    @media screen and (max-width: $break-point) {
      flex-direction: column;
    }
  }


  &__benefits-for-you {
    text-align: center;

    &__video {
      width: min(90%, 1075px);
      aspect-ratio: 16/9;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: $violet;
    color: #fff;

    @media screen and (min-width: $break-point) {
      grid-template-columns: 1fr 3fr;
      border-radius: 18px 18px 0 0;

      margin-top: 18px;
    }

    &__nav {
      grid-row: auto / span 2;

      @media screen and (max-width: $break-point) {
        grid-column: 1;
        grid-row: 2;
      }
    }

    &__subscribe {
      grid-row: 1;
      grid-column: 1 / span 2;

      @media screen and (min-width: $break-point) {
        grid-column: 2;
      }

      &__input-row {
        @media screen and (min-width: $small-break-point) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    &__copyright {
      display: flex;
      align-items: flex-end;
      grid-column: 2;
      grid-row: 2;
      padding-bottom: 28px;

      &__logo {
        filter: invert(100%) contrast(0) brightness(170%);
        max-width: 180px;
        padding-bottom: 16px;

        margin-right: 24px;
      }

      &__text {
        display: none;

        @media screen and (min-width: $break-point) {
          display: unset;
        }
      }
    }

    &__title {
      font-size: 28px;
      text-align: center;

      @media screen and (min-width: $break-point) {
        text-align: unset;
      }

      &__link {
        color: #f2f2f2;
      }
    }

    &__input {
      flex: 1;
      margin-right: 18px;
    }

    &__button {
      width: calc(100% - 18px);
      height: 36px;

      margin-top: 24px;

      display: flex;
      justify-content: center;

      @media screen and (min-width: $small-break-point) {
        width: unset;
        margin-top: unset;
      }
    }

    &__text {
      grid-column: 1 / span 2;
      text-align: center;

      @media screen and (min-width: $break-point) {
        display: none;
      }
    }
  }
}

.slider {
  @media screen and (min-width: $break-point) {
    display: none;
  }
}
