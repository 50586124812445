@import "../../utils/styles/colors";

.wrapper {
  margin: 10px 20px;
  text-align: left;

  &--reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 0;
  }

  &--table {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    &--orange,
    &--violet,
    &--table {
      font-size: 14px;
    }

    &--orange {
      color: $orange;
    }

    &--soft-blue {
      color: $soft-blue;
    }

    &--violet {
      color: $violet;
    }

    &--normal-weight {
      font-weight: normal;
    }

    &--xxl {
      font-size: 30px;
    }

    &--medium {
      font-size: 18px;
    }
  }

  &__subtitle {
    color: $day-purple;
    font-size: 12px;
    line-height: 19px;

    &--table {
      font-size: 14px;
      font-weight: bold;
      color: black;

      &--orange {
        color: $orange;
      }

      &--violet {
        color: $violet;
      }
    }
  }
}
