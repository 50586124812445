@import "./utils/styles/variables";

body {
  margin: 0;
  font-family: $rawson;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $soft-gray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  justify-content: center;

  height: 100vh;
}
