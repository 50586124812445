@import "../../utils/styles/colors";

:root {
  --progress: 0%;
}

.wrapper {
  margin: 10px;
  width: 60%;

  border-radius: 4px;
  background-color: #f2f2f2;

  &__bar {
    transition: max-width cubic-bezier(0.68, -0.55, 0.265, 1.55) 600ms;

    max-width: var(--progress);
    border-radius: 4px;
    height: 8px;

    background-color: $orange;
  }
}
