$white: #fff;
$black: #000;

$soft-gray: #f9f8fd;
$violet: #21014b;
$soft-violet: #8d7da5;
$orange: #ff8d3d;
$soft-blue: #7c76b5;

$dark-purple: #21014c;
$day-purple: #aba7cc;

$day-purple-50: rgba(171, 167, 204, 0.5);

$green: #6fcf97;

$purple: #861df0;

$gray: #c4c4c4;
$gray-2: #4f4f4f;

$red: #ff0000;
