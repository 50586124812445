@import '../../utils/styles/colors';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  
  &__title {
    display: flex;
    gap: 8px;
    font-size: 16px;
    font-weight: 700;

    &__icon {
      width: 16px;
    }
  }
}

.title {
  min-width: 144px;
  color: $dark-purple;
  margin: 0;
}