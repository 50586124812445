@import "../../utils/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 6vh 4vw;

  background-color: $white;
  text-align: center;

  @media screen and (min-height: 800px) {
    padding: 18vh 4vw;
  }

  &__header {
    max-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-family: $rawson;
      margin: 0;
      font-weight: 600;
      font-size: 28px;
      line-height: 101.5%;

      color: $dark-purple;

      margin-bottom: 18px;
    }
  }

  &__form {
    --columns: 1fr;

    display: grid;
    grid-template-columns: var(--columns);
    row-gap: 10px;
    column-gap: 20px;
    grid-auto-rows: max-content;
    grid-template-rows: fit-content(40%);

    align-items: center;

    width: 100%;
    margin-bottom: 14px;

    @media screen and (min-width: 768px) {
      --columns: 1fr 1fr;
      min-width: 696px;

      &--no-height {
        min-height: auto;
      }

      &--last-step {
        --columns: 1fr;
        align-content: center;
        justify-items: center;
      }
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.step-three {
  width: 420px;
}
