@import "../../utils/styles/colors";

$border-radius: 8px;

.wrapper {
  &__input {
    max-height: 52px;
  }

  &__list {
    position: relative;
    list-style: none;

    padding: $border-radius;

    background-color: #fff;
    border: 1px solid $day-purple-50;
    border-radius: $border-radius;

    &__item {
      line-height: 30px;

      &:first-child {
        & > a {
          &:hover, &:focus {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
          }
        }
      }

      &:last-child {
        & > a {
          &:hover, &:focus {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
        }
      }

      &__prediction {
        text-decoration: unset;
        color: $violet;
        display: block;
  
        &:hover, &:focus {
          background-color: $day-purple-50;
          cursor: pointer;
          outline: none;
        }
      }
    }
  }
}
