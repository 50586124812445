.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;

  &__title {
    width: auto;
    margin: 0;
    margin-right: 4px;
  }
}

.divider {
  display: flex;
  flex: 1;
  padding-left: 12px;

  border: 0;
  border-top: 1px solid #ebe9f9;

  &--vertical {
    width: auto;
    height: 80%;
    min-height: 42px;
    flex: none;
    border: 0;
    border-left: 1px solid #ebe9f9;
    margin: 0;
  }
}
