@import "../../../../utils/styles/colors";

$margin-unit: 14px;
$width: 100%;

.wrapper {
  margin-bottom: $margin-unit;
}

.list-card {
  padding: 2px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  margin: 12px;

  box-shadow: -2px 3px 14px -3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: -2px 3px 14px -3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: -2px 3px 14px -3px rgba(0, 0, 0, 0.4);

  &-footer {
    margin: 0px;
    display: flex;
    justify-content: space-between;

    & > button {
      background-color: #fff;
      border: none;
    }

    &__last-location {
      margin-top: 2px;
      color: #797382;
    }
  }

  &--collapsed {
    @extend .list-card;
    text-align: center;
  }

  &--selected {
    @extend .list-card--collapsed;
    border: 1px solid $purple;
  }

  &:hover {
    border: 1px solid $purple;
    cursor: pointer;
  }

  &--avatar {
    width: 39px;
    height: 39px;
    object-fit: cover;
    border-radius: 50%;
    display: block;
  }

  .image-container {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 39px;
  }

  .red-dot {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 0.8px solid #fff;
    background-color: red;
  }

  &--name--container,
  &--description {
    margin-left: 12px;
    margin-bottom: 4px;
  }

  &--name--container {
    display: flex;
    align-items: flex-start;

    justify-content: space-between;
  }

  &--name {
    font-size: 16px;
    line-height: 16px;
    margin: 0px;
    text-align: start;
  }

  &--status {
    font-size: 12px;
    font-weight: 500;
    background-color: #ecd8ff;
    padding: 0px 7px 1px 7px;
    border-radius: 100px;
    color: $purple;
    white-space: nowrap;

    > small {
      font-size: 12px;
      margin-left: 4px;
    }
    &--offline {
      @extend .list-card--status;
      background-color: #d7d5eb;
      color: #484565;
    }
    &--pending {
      @extend .list-card--status;
      background-color: #ffe3e1;
      color: #eb5757;
    }
  }

  &--description {
    display: flex;
    text-align: center;

    &--icon {
      margin-right: 8px;
    }

    > small {
      color: #797382;
    }
  }
}
