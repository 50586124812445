.wrapper {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  height: 95%;
}

.footer-container {
  width: 100%;

  display: flex;
  justify-content: center;

  margin: 18px 0;
}

.request-error{
  display: inline-block;
  text-align: left;

  margin-left: 8px;
  margin-bottom: 18px;

  &__text {
    color: red;
  }
}