@import "../../../../utils/styles/colors";

.wrapper {
  text-align: left;
  padding-left: 18px;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__button {
      padding: 0;
      margin-left: 14px;
    }
  }
  p {
    margin-block-start: 4px;
    margin-block-end: 4px;
  }
  h5 {
    margin-block-end: 0;
  }
  &__title {
    margin-bottom: 0;
    &::before {
      content: "";

      display: inline-block;
      width: 10px;
      height: 10px;

      margin-right: 10px;
      margin-left: -6px;
      margin-bottom: -2px;

      border: 3px solid $orange;
      background-color: $orange;

      border-radius: 50%;
    }
    &--finished {
      &::before {
        border-color: $green;
        background-color: $green;
      }
    }
    &--current {
      &::before {
        border-color: $purple;
        background-color: $purple;
      }
    }
  }

  &__stop-container {
    border-left: 2px dashed $violet;
    padding-left: 18px;

    &--last {
      border-left: 0;
    }
  }
}
