@import "../../utils/styles/colors";

.wrapper {
  background-color: $white;
  border-radius: 10px;

  padding: 16px 14px;

  box-shadow: -12px 10px 45px rgba(0, 0, 0, 0.04);
}
