@import "../../../../utils/styles/variables";

.wrapper {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $mobile-breakpoint) {
    height: 100%;
  }
}

.map {
  height: calc(100% - 50px);
  
  border-radius: 12px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  margin: 12px;
  padding: 12px;

  &__top {
    display: flex;
    align-items: flex-start;
  }
  
  &__bottom {
    display: flex;
    flex-direction: column;
  
    gap: 4px;
  
    align-items: flex-end;
    justify-content: flex-end;
  
    & > :nth-child(1) {
      margin-bottom: 32px;
    }
  }
}
