.wrapper {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__package-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & > div {
      flex: 1;
      margin-right: 8px;
      margin-bottom: 0;

      &:first-child {
        flex: 2.3;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__message {
    display: flex;
    align-self: flex-start;
    font-style: italic;
    font-size: 14px;
  }
}

.margin-bottom {
  margin-bottom: 20px;
}
