@import "../../utils/styles/variables";

@mixin gradient-button ($darkColor, $lightColor) {
  color: #fff;

  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(0%, $darkColor),
    color-stop(100%, $lightColor)
  );

  background-image: -moz-linear-gradient(
    bottom,
    $lightColor 0%,
    $darkColor 100%
  );
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  height: 25px;

  padding: 16px 24px;
  margin: 4px;

  border: 0;
  border-radius: 16px;

  font-family: $rawson;
  font-weight: 600;
  font-size: 16px;

  &:disabled {
    filter: grayscale(100%);
  }

  &--loading {
    &::before {
      content: "";

      width: 14px;
      height: 14px;

      border: 2px solid #f3f3f3; /* Light grey */
      border-top: 2px solid #3498db; /* Blue */
      border-radius: 50%;
      animation: spin 2s linear infinite;
    }
  }

  &--loading-right {
    &::after {
      content: "";

      width: 14px;
      height: 14px;

      border: 2px solid #f3f3f3; /* Light grey */
      border-top: 2px solid #3498db; /* Blue */
      border-radius: 50%;
      animation: spin 2s linear infinite;
    }
  }

  &--hidden {
    visibility: hidden;
  }

  &--xxl {
    min-height: 52px;
    padding: 24px 32px;
    border-radius: 32px;
  }
  
  // @TODO [salva@11-05-2025] add variables with this colors.
  &--gradient {
    @include gradient-button(#a923d7, #791af9);
  }

  &--dark-gradient {
    @include gradient-button($dark-purple, #593b81);
  }

  &--orange-gradient {
    @include gradient-button(#ff5d37, #ffad40);
  }

  &--green-gradient {
    @include gradient-button(#56d062, #0c7016);
  }

  &--clear {
    background: none;

    &__icon {
      margin-right: 0;
    }
  }

  &--bg-orange,
  &--bg-violet {
    color: #fff;
  }

  &--bg-orange {
    background-color: $orange;
  }

  &--bg-violet {
    background-color: $violet;
  }

  &__icon,
  &__title {
    margin: 0;
    margin-left: 4px;
  }
  
  &__title {
    min-width: 80px;
    
    &--small {
      min-width: unset;
    }
  }

  &__icon {
    display: inherit;
    margin-left: 0;

    &--only {
      margin: 0;
    }

    &--right-icon {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &--small {
    padding: 4px 12px;
    height: unset;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
