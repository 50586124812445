@import "../../utils/styles/variables";

.header {
  width: 100%;
  min-width: $mobile-breakpoint;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__bell-icon {
    margin-top: 8px;
  }

  &__background {
    width: 100%;
    height: 120px;

    background-color: $violet;

    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: space-around;

    &__elementsContainer {
      width: 100%;
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      padding: 20px;
    }
  }

  &__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: -40px;

    & > hr {
      width: 100%;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    min-width: unset;
    margin-top: unset;
    
    &__background {
      width: 100%;
      height: auto;
      background-color: unset;
      padding: unset;
      margin: unset;
      
      &__elementsContainer {
        padding: unset;
        margin: unset;

        & > a > img {
          width: 80px;
        }
      }
    }

    &__profile {
      width: 100%;
      margin-top: unset;
      padding: 0;
    }
  }
}

.hidden { visibility: hidden; }
