@import "../../../utils/styles/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16vh;

  &__message,
  &__title {
    font-size: 16px;
    line-height: 22px;

    color: $gray-2;
  }

  &__title {
    font-weight: bold;
    margin: 4px auto;
  }

  &__message {
    margin-top: 0;
    max-width: 326px;
  }
}
