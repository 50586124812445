@import "../../utils/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 18vh 4vw;

  background-color: $white;
  justify-content: space-evenly;
  min-height: 50vh;
  text-align: center;

  &__title,
  &__sub-title,
  &__description {
    font-family: $rawson;
    margin: 0;
  }

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 101.5%;

    color: $dark-purple;

    margin-bottom: 18px;
  }

  &__sub-title {
    font-size: 18px;
    line-height: 130%;
    max-width: 300px;
    margin-bottom: 50px;
  }

  &__description {
    font-size: 16px;
    line-height: 19px;

    color: #b7b7b7;
  }

  &__login-error {
    font-size: 16px;
    line-height: 19px;

    color: $red;
  }

  &__social-button {
    justify-content: flex-start;
    background: $white;

    height: 52px;

    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: -12px 10px 45px rgba(0, 0, 0, 0.04);

    font-family: $rawson;
    font-size: 18px;
    line-height: 22px;

    color: $dark-purple;

    & > p {
      padding-right: 10px;
    }
  }

  &__logo {
    margin-top: 50px;
  }
}
