.item-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.search-bar {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 16px;

  margin: 4px;
  padding: 12px;

  &__right-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}
